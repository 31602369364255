import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Status from './Status';
import DividerBar from './DividerBar';
import PageModal from './PageModal';
import { v4 as uuidv4 } from 'uuid';
import { defaultPageAttributes } from './Page'; // Import the default attributes
import React, { useState, useEffect } from 'react';

const statuses = ["prospects", "onboarding", "review", "create community", "create phone line", "pending guide", "create guide", "pending video", "create video", "add to content hub", "completed"];

const AssemblyLine = () => {
    const [lines, setLines] = useState([]);
    const [selectedLine, setSelectedLine] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPages, setFilteredPages] = useState([]);
    const [selectedPageIds, setSelectedPageIds] = useState([]);
    const [resetDuplicateSummaryTrigger, setResetDuplicateSummaryTrigger] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            console.log('AssemblyLine: Starting data fetch');
            const apiEndpoint = `https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page`;

            try {
                console.log('AssemblyLine: Fetching data from:', apiEndpoint);
                const response = await fetch(apiEndpoint, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();
                console.log('AssemblyLine: Raw data received:', data);
                const pages = data.items ? data.items : data;
                console.log('AssemblyLine: Number of pages fetched:', pages.length);
                console.log('AssemblyLine: First few pages:', pages.slice(0, 5));
                console.log('AssemblyLine: Distribution of pages by status:');
                const distribution = pages.reduce((acc, page) => {
                    acc[page.status] = (acc[page.status] || 0) + 1;
                    return acc;
                }, {});
                console.log(distribution);
                // Add unique IDs to pages that don't have them
                const pagesWithIds = pages.map(page => ({
                    ...page,
                    id: page.id || page.name // Use name as ID if id is not present
                }));
                console.log('AssemblyLine: Pages with IDs:', pagesWithIds.slice(0, 5));
                setLines(pagesWithIds);
            } catch (error) {
                console.error('AssemblyLine: Error fetching data:', error.message, 'Endpoint:', apiEndpoint);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        console.log('AssemblyLine: lines state updated, new length:', lines.length);
        console.log('AssemblyLine: First few lines:', lines.slice(0, 5));
    }, [lines]);

    useEffect(() => {
        console.log('AssemblyLine: filteredPages updated, new length:', filteredPages.length);
        console.log('AssemblyLine: Distribution of filteredPages by status:');
        const distribution = filteredPages.reduce((acc, page) => {
            acc[page.status] = (acc[page.status] || 0) + 1;
            return acc;
        }, {});
        console.log(distribution);
    }, [filteredPages]);

    useEffect(() => {
        console.log('AssemblyLine: Filtering pages. SearchTerm:', searchTerm);
        if (searchTerm) {
            const filtered = lines.filter(page => 
                Object.values(page).some(value => 
                    String(value).toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setFilteredPages(filtered);
        } else {
            setFilteredPages(lines);
        }
        console.log('AssemblyLine: Number of filtered pages:', filteredPages.length);
        console.log('AssemblyLine: First few filtered pages:', filteredPages.slice(0, 5));
    }, [searchTerm, lines]);

    const addNewPage = (status) => {
        const pageId = uuidv4(); // Generate a unique page_id
    
        const newPage = {
            id: pageId,
            name: 'undefined', // Explicitly set name to 'undefined'
            status: status, // Use the status passed to the function
            ...defaultPageAttributes // Apply default attributes, assuming these are correctly set to ''
        };
    
        // No need to modify other attributes to 'undefined' as per your latest instruction
    
        setLines(prevLines => [...prevLines, newPage]);
    
        const apiEndpoint = 'https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page';
    
        const pageData = {
            ...newPage,
            id: pageId, // Ensure 'id' is included in the payload
        };
    
        console.log("Sending pageData:", pageData);
    
        fetch(apiEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pageData),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Page created successfully', data);
        })
        .catch(error => {
            console.error('Error during page creation:', error);
        });
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // This function is called when a page is clicked
    const handlePageClick = (pageAttributes) => {
        setSelectedLine(pageAttributes);
    };

    const handleSaveEdit = (editedLine) => {
        const updatedLines = lines.map(line => {
            if (line.id === editedLine.id) {
                // Use the entire editedLine object for the update
                return { ...editedLine };
            }
            return line;
        });
        setLines(updatedLines);
    };    

    const handleDeletePage = async (name) => {
        try {
            const encodedName = encodeURIComponent(name);
            const apiEndpoint = `https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page?name=${encodedName}`;
            const response = await fetch(apiEndpoint, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Assuming the backend successfully deletes the page, update the state to remove the page from the UI
            setLines(lines.filter(line => line.name !== name));
        } catch (error) {
            console.error('Error during page deletion:', error);
        }
    };

    const closeModal = () => {
        setSelectedLine(null);
    };
    
    const selectAllPagesInView = (status, pageIdsInStatus) => {
        console.log(`selectAllPagesInView called for status: ${status}`, pageIdsInStatus);
        console.log('Current selectedPageIds:', selectedPageIds);

        if (pageIdsInStatus.length === 0) {
            console.log('No pages to select in this status');
            return;
        }

        const areAllSelected = pageIdsInStatus.every(pageId => selectedPageIds.includes(pageId));
        console.log('Are all pages in this status already selected?', areAllSelected);

        if (areAllSelected) {
            // Deselect only the pages in this status
            setSelectedPageIds(currentSelectedPageIds => {
                const newSelection = currentSelectedPageIds.filter(id => !pageIdsInStatus.includes(id));
                console.log('Deselecting. New selection:', newSelection);
                return newSelection;
            });
        } else {
            // Select all pages in this status, keeping other selections intact
            setSelectedPageIds(currentSelectedPageIds => {
                const newSelection = [
                    ...currentSelectedPageIds.filter(id => !pageIdsInStatus.includes(id)),
                    ...pageIdsInStatus
                ];
                console.log('Selecting. New selection:', newSelection);
                return newSelection;
            });
        }
    };

    const moveSelectedPages = async (direction, status) => {
        // Filter out the pages that belong to the current status
        const pagesInCurrentStatus = lines.filter(line => line.status === status);
        const selectedPagesInCurrentStatus = pagesInCurrentStatus.filter(page => selectedPageIds.includes(page.id));
    
        // Determine the new status based on the direction
        const currentIndex = statuses.indexOf(status);
        const newIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;
    
        // Update the status of the selected pages
        const updatedLines = lines.map(line => {
            if (selectedPagesInCurrentStatus.find(page => page.id === line.id)) {
                if ((direction === 'left' && currentIndex > 0) || (direction === 'right' && currentIndex < statuses.length - 1)) {
                    return { ...line, status: statuses[newIndex] };
                }
            }
            return line;
        });
    
        // Update local state first
        setLines(updatedLines);
    
        // Filter out the lines that were actually moved to avoid unnecessary API calls
        const movedLines = updatedLines.filter(line => selectedPageIds.includes(line.id));
    
        // Update the status for each moved page in the backend
        for (const page of movedLines) {
            const pageData = { status: page.status };
            const apiEndpoint = `https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page?name=${encodeURIComponent(page.name)}`;
    
            try {
                const response = await fetch(apiEndpoint, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(pageData),
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                console.log('Page status updated successfully', await response.json());
            } catch (error) {
                console.error('Error during page status update:', error);
            }
        }
        console.log('Setting resetDuplicateSummaryTrigger to true');
        setResetDuplicateSummaryTrigger(true);
    };
    
    const onDragEnd = async (result) => {
        const { destination, source, draggableId } = result;
        if (!destination) return;
    
        // Start with an empty array for pages to update
        let pagesToUpdate = [];
    
        // If multiple pages are selected, prepare to update all selected pages
        if (selectedPageIds.length > 1 && selectedPageIds.includes(draggableId)) {
            pagesToUpdate = lines.filter(line => selectedPageIds.includes(line.id));
        } else {
            // Otherwise, prepare to update just the dragged page
            const page = lines.find(line => line.id === draggableId);
            if (page) {
                pagesToUpdate = [page];
            }
        }
    
        // Update the local state to reflect the new order and status
        const newLines = Array.from(lines);
        pagesToUpdate.forEach(page => {
            const pageIndex = newLines.findIndex(line => line.id === page.id);
            if (pageIndex !== -1) {
                newLines.splice(pageIndex, 1); // Remove from current position
            }
            page.status = destination.droppableId; // Update status
            newLines.splice(destination.index, 0, page); // Insert at new position
        });
    
        setLines(newLines);
        setSelectedPageIds([]); // Clear selection after moving
    
        // Update the status for each page in the backend
        for (const page of pagesToUpdate) {
            const pageData = { status: page.status };
            const apiEndpoint = `https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page?name=${encodeURIComponent(page.name)}`;
    
            try {
                const response = await fetch(apiEndpoint, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(pageData),
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                console.log('Page status updated successfully', await response.json());
            } catch (error) {
                console.error('Error during page status update:', error);
            }
        }
        setResetDuplicateSummaryTrigger(true);
    };

    // This function should be passed down to Status and called after the effect runs
    const onResetDuplicateSummaryDone = () => {
        console.log('Resetting resetDuplicateSummaryTrigger to false');
        setResetDuplicateSummaryTrigger(false);
    };

    return (
        <>
            <DividerBar onSearchChange={handleSearchChange} />
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="assembly-line">
                    {statuses.map(status => (
                        <Droppable droppableId={status} key={status}>
                            {(provided, snapshot) => {
                                const linesForStatus = filteredPages.filter(line => line.status === status);
                                console.log(`AssemblyLine: Status ${status}, number of lines:`, linesForStatus.length);
                                return (
                                    <div 
                                        ref={provided.innerRef} 
                                        {...provided.droppableProps}
                                        style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'white' }}
                                        className="status-box"
                                    >
                                        <Status 
                                            title={status} 
                                            lines={linesForStatus}
                                            addNewPage={() => addNewPage(status)} 
                                            onSelectAll={selectAllPagesInView}
                                            onPageClick={handlePageClick}
                                            selectedPageIds={selectedPageIds}
                                            moveSelectedPages={moveSelectedPages}
                                            resetDuplicateSummaryTrigger={resetDuplicateSummaryTrigger}
                                            onResetDuplicateSummaryDone={onResetDuplicateSummaryDone}
                                        />
                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    ))}
                    <PageModal line={selectedLine} onClose={closeModal} onSave={handleSaveEdit} onDelete={handleDeletePage} />
                </div>
            </DragDropContext>
        </>
    );
};

export default AssemblyLine;