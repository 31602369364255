// src/components/Page.js
import React from 'react';

export const defaultPageAttributes = {
    page_id: '',
    name: '',
    new_agency_name: '',
    agencyName: '',
    agencyPhoneNumber: '',
    twimlPlayDigits: '',
    timeZone: '',
    openHours: '',
    closeHours: '',
    priceCents: '',
    categorySlug: '',
    communitySlug: '',
    articleSlug: '',
    article_url: '',
    stamp_url: '',
    url: '',
    about: ''
    // ... other attributes
};

const Page = ({ line, onClick }) => {
    return (
        <div className="page" onClick={() => onClick(line)} style={{ cursor: 'pointer' }}>
            <h3>{line.name || 'Untitled'}</h3>
            {/* Display other metadata as needed */}
        </div>
    );
};

export default Page;
