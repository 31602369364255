// src/App.js
import React from 'react';
import './App.css';
import AssemblyLine from './components/AssemblyLine';

function App() {
  // Ensure that the App and App-header classes do not have conflicting CSS properties.
  // Check your App.css file to ensure there's no overflow or height restrictions on these classes.
  return (
    <div className="App">
      <header className="App-header">
      </header>
      {/* AssemblyLine and other content will scroll under the sticky DividerBar */}
      <AssemblyLine />
    </div>
  );
}

export default App;