import React from "react";
import { styled, alpha } from "@mui/material/styles";
import { Box, InputBase, Divider, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const bannerImageUrl = 'https://s3.us-west-1.amazonaws.com/yrcdn.net/assets/phone-line-assembly.webp';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  margin: theme.spacing(1, 0),
  width: 'auto',
  fontFamily: "'SF Pro', sans-serif", // Apply SF Pro font here as well
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
    fontFamily: "'SF Pro', sans-serif", // Apply SF Pro font here
  },
}));

const DividerBar = ({ onSearchChange }) => {
    return (
      <Box sx={{ 
        backgroundColor: '#fff',
        width: '100%',
      }}>
        <img 
          src={bannerImageUrl} 
          alt="Banner" 
          style={{ 
            width: '100%', 
            maxHeight: '100px',
            display: 'block',
            objectFit: 'cover'
          }} 
        />
        <Box sx={{ 
          position: 'sticky',
          top: 0,
          zIndex: 1100,
          backgroundColor: '#fff',
          display: 'flex', 
          alignItems: 'center', 
          padding: '8px 0',
          height: '64px',
        }}>
          <Typography variant="h6" component="h1" sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            marginRight: '36px', 
            fontWeight: 900,
            fontSize: '2.25rem',
            color: '#333',
            fontFamily: "'SF Pro', sans-serif", // Apply SF Pro font here
          }}>
            <span role="img" aria-label="factory" style={{ marginRight: '10px' }}>🏭</span>
            <a href="https://us-west-1.console.aws.amazon.com/dynamodbv2/home?region=us-west-1#item-explorer?operation=QUERY&table=phone_line_assembly" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="claimyr-link"
                style={{ color: 'inherit', textDecoration: 'none' }}>
                Claimyr Phone Line Assembly
            </a>
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={onSearchChange} // Use the passed function for handling search input changes
            />
          </Search>
        </Box>
        <Divider sx={{ borderColor: '#666', my: 2, width: '100%', backgroundColor: '#fff' }} />
      </Box>
    );
  };

export default DividerBar;