import React, { useEffect, useState } from 'react';

const PageModal = ({ line, onClose, onSave, onDelete }) => {
    const [attributes, setAttributes] = useState({ name: '', ...line });

    useEffect(() => {
        setAttributes({ ...line });
    }, [line]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setAttributes(prevAttributes => ({
            ...prevAttributes,
            [name]: value
        }));
    };

    const handleDelete = () => {
        if (attributes.name) {
            onDelete(attributes.name); // Use the page's name instead of its id
            onClose();
        } else {
            console.error('Error: Page name is required for deletion.');
        }
    };

    const handleSave = () => {
        if (!attributes.name) {
            console.error('Error: Name is required.');
            return;
        }

        console.log('Saving', attributes);
        fetch('https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(attributes),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Saved', data);
            onSave(attributes);
        })
        .catch(error => console.error('Error during save:', error));
    };

    const isURL = (value) => {
        try {
            new URL(value);
            return true;
        } catch (e) {
            return false;
        }
    };

    const dynamoDBConsoleURL = (name) => {
        return `https://us-west-1.console.aws.amazon.com/dynamodbv2/home?region=us-west-1#edit-item?itemMode=2&pk=${encodeURIComponent(name)}&route=ROUTE_ITEM_EXPLORER&sk=&table=phone_line_assembly`;
    };

    if (!line) return null;

    return (
        <div className="page-modal-backdrop" onClick={onClose}>
            <div className="page-modal" onClick={e => e.stopPropagation()}>
                <button onClick={handleDelete}>Delete</button>
                <button onClick={handleSave}>Save</button>
                {Object.entries(attributes).map(([key, value]) => (
                    <div key={key} className="page-modal-attribute">
                        <label>
                            {key.replace(/_/g, ' ')}:
                            {isURL(value) && (
                                <a href={value} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px' }}>
                                    Open Link
                                </a>
                            )}
                            {key === 'name' && (
                                <a href={dynamoDBConsoleURL(value)} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px', color: 'blue' }}>
                                    DynamoDB Item
                                </a>
                            )}
                        </label>
                        <input
                            type="text"
                            name={key}
                            value={value}
                            onChange={handleChange}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PageModal;