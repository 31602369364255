import React, { useState, useEffect } from 'react';
import Page from './Page';
import { Draggable } from 'react-beautiful-dnd';
import '../App.css'; // Ensure this path is correct

// Replace 'your_access_token_here' with the actual token or retrieve it from props/state
const accessToken = 'feaa05ac583517b45a548efe90eec4b418dc479f23b03a275abc60858a50cb25';

const claimyr_headers = {
  "Authorization": "Bearer " + accessToken,
};

const Status = ({ title, lines, addNewPage, onPageClick, onSelectAll, selectedPageIds, moveSelectedPages, resetDuplicateSummaryTrigger, onResetDuplicateSummaryDone }) => {
  const arrowButtonStyle = { fontSize: '3em' };
  const pageCount = lines.length;
  const [duplicateFound, setDuplicateFound] = useState(false);
  const [duplicateSummary, setDuplicateSummary] = useState('');

  useEffect(() => {
    console.log('Effect triggered', resetDuplicateSummaryTrigger);
    if (resetDuplicateSummaryTrigger) {
        setDuplicateSummary(''); // Clear the duplicate summary message
        setDuplicateFound(false); // Optionally reset other related state
        onResetDuplicateSummaryDone(); // Notify the parent that the reset is done
    }
}, [resetDuplicateSummaryTrigger, onResetDuplicateSummaryDone]);

  const createCommunity = async () => {
    let communityAddedCount = 0;
    let duplicateCommunityCount = 0;
    setDuplicateSummary('Checking for duplicate communities...');

    const selectedPages = lines.filter(line => selectedPageIds.includes(line.id));
    for (let index = 0; index < selectedPages.length; index++) {
      const page = selectedPages[index];
      const pageNumber = index + 1;
      const pageOrdinal = pageNumber === 1 ? '1st' : pageNumber === 2 ? '2nd' : pageNumber === 3 ? '3rd' : `${pageNumber}th`;
      setDuplicateSummary(`Checking ${pageOrdinal} community for duplicates...`);

      const checkEndpoint = `https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page?communitySlug=${encodeURIComponent(page.communitySlug)}&checkDuplicate=true`;
      const checkResponse = await fetch(checkEndpoint, {
        method: 'GET',
        headers: { "Authorization": "Bearer " + accessToken },
      });
      const checkData = await checkResponse.json();

      if (checkData && checkData.items && checkData.items.length > 0) {
        duplicateCommunityCount++;
        setDuplicateFound(true);
      } else {
        const url = 'https://claimyr.engineering/community';
        const headers = {
          'Authorization': `Bearer ${accessToken}`,
          'Community-Slug': page.communitySlug,
          'Community-Name': page.name,
          'Has-Banner-Image-Upload': '0'
        };

        try {
          const response = await fetch(url, {
            method: 'PUT',
            headers: headers
          });
          if (response.status !== 200) {
            console.error(`Failed to create a new community. Status code: ${response.status}. Response: ${await response.text()}`);
          } else {
            console.log(`Successfully created a new community. Response: ${await response.text()}`);
            communityAddedCount++;
          }
        } catch (error) {
          console.error(`Error creating community: ${error}`);
        }
      }
      await new Promise(resolve => setTimeout(resolve, 0));
    }

    let finalSummary = '';
    if (duplicateCommunityCount > 0) {
      finalSummary += `${duplicateCommunityCount} duplicate communities found. `;
    }
    if (communityAddedCount > 0) {
      finalSummary += `${communityAddedCount} communities added. `;
    }
    if (finalSummary === '') {
      finalSummary = 'No duplicate communities found. No communities added.';
    }
    setDuplicateSummary(finalSummary + 'Process complete.');
  };

  const createPhoneLines = async () => {
    setDuplicateFound(false);
    setDuplicateSummary('Checking for duplicates...');
    let duplicateCount = 0;
    let phoneLinesAddedCount = 0;

    const selectedPages = lines.filter(line => selectedPageIds.includes(line.id));
    for (let index = 0; index < selectedPages.length; index++) {
      const page = selectedPages[index];
      const pageNumber = index + 1;
      const pageOrdinal = pageNumber === 1 ? '1st' : pageNumber === 2 ? '2nd' : pageNumber === 3 ? '3rd' : `${pageNumber}th`;
      setDuplicateSummary(`Checking ${pageOrdinal} page for duplicates...`);

      try {
        // Check for duplicates
        const checkEndpoint = `https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page?name=${encodeURIComponent(page.name)}&checkDuplicate=true`;
        const checkResponse = await fetch(checkEndpoint, {
          method: 'GET',
          headers: { ...claimyr_headers },
        });

        if (!checkResponse.ok) {
          console.error(`Failed to check for duplicates. Status: ${checkResponse.status}`);
          setDuplicateSummary(`Failed to check for duplicates. Status: ${checkResponse.status}`);
          continue; // Skip to the next iteration
        }

        const checkData = await checkResponse.json();

        if (checkData && checkData.item) {
          // Use the item data to create the new phone line
          const item = checkData.item;
          const data = {
            "stateName": item.name,
            "agencyName": item.agencyName,
            "agencyPhoneNumber": item.agencyPhoneNumber ? item.agencyPhoneNumber.replace(/^\+1/, '') : '',
            "twimlPlayDigits": item.twimlPlayDigits,
            "openHours": item.openHours,
            "closeHours": item.closeHours,
            "priceCents": item.priceCents,
            "timeZone": item.timeZone,
            "communitySlug": item.communitySlug,
            "isFree": true,
            "isPublic": true,
            "isCorked": true
          };

          const response = await fetch('https://claimyr.engineering/state', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', ...claimyr_headers },
            body: JSON.stringify(data)
          });

          if (!response.ok) {
            const errorText = await response.text();
            console.error(`Failed to create a new phone line. Status code: ${response.status}. Response: ${errorText}`);
            setDuplicateSummary(`Failed to create phone line: ${errorText}`);
          } else {
            console.log(`Successfully created a new phone line. Response: ${await response.text()}`);
            phoneLinesAddedCount++;
          }
        } else {
          console.error('No item data found');
          setDuplicateSummary('Failed to create phone line: No item data found');
        }
      } catch (error) {
        console.error(`Error processing page ${page.name}: ${error}`);
      }

      // Ensure UI updates after each iteration
      await new Promise(resolve => setTimeout(resolve, 0));
    }

    // Set final summary
    let finalSummary = '';
    if (duplicateCount > 0) {
      finalSummary += `${duplicateCount} duplicates found. `;
    }
    if (phoneLinesAddedCount > 0) {
      finalSummary += `${phoneLinesAddedCount} phone lines added. `;
    }
    if (finalSummary === '') {
      finalSummary = 'No duplicates found. No phone lines added.';
    }
    setDuplicateSummary(finalSummary + 'Process complete.');
  };

  const handleSelectAll = () => {
    console.log(`Status ${title}: handleSelectAll called`);
    console.log(`Status ${title}: Number of lines:`, lines.length);
    console.log(`Status ${title}: First few lines:`, lines.slice(0, 5));
    
    console.log(`Status ${title}: ID details of first few lines:`, 
      lines.slice(0, 5).map(line => ({
        id: line.id,
        typeOfId: typeof line.id,
        hasId: 'id' in line
      }))
    );

    const pageIdsInThisStatus = lines.map(line => line.id);
    console.log(`Status ${title}: Selecting all pages`, pageIdsInThisStatus);
    onSelectAll(title, pageIdsInThisStatus);
  };

  console.log(`Status ${title}: Component rendered`);
  console.log(`Status ${title}: Number of lines:`, lines.length);
  console.log(`Status ${title}: First few lines:`, lines.slice(0, 5));

  return (
    <div className="status">
      <div className="status-header">
        <h2>{title} ({pageCount})</h2>
        <button onClick={addNewPage}>+</button>
        <button onClick={handleSelectAll}>Select All</button>
      </div>
      <div className="move-buttons">
        {selectedPageIds.some(id => lines.find(page => page.id === id)) && (
          <>
            <button onClick={() => moveSelectedPages('left', title)} style={arrowButtonStyle}>⬅️</button>
            {title === "create community" && (
              <button onClick={createCommunity} className="create-community-btn">Create Community</button>
            )}
            {title === "create phone line" && (
              <button onClick={createPhoneLines} className="create-phone-line-btn">Create Phone Line</button>
            )}
            <button onClick={() => moveSelectedPages('right', title)} style={arrowButtonStyle}>➡️</button>
          </>
        )}
      </div>
      {duplicateFound && (
        <div className="duplicate-message">
          {duplicateSummary}
        </div>
      )}
      {lines.map((line, index) => {
        const draggableId = line.id || `fallback-${index}`;
        return (
          <Draggable key={draggableId} draggableId={draggableId} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={selectedPageIds.includes(line.id) ? 'selected' : ''}
                style={{
                  ...provided.draggableProps.style,
                  backgroundColor: selectedPageIds.includes(line.id) ? '#D2EFD2' : '',
                  border: selectedPageIds.includes(line.id) ? '2px solid #4CAF50' : '',
                }}
              >
                <Page line={line} onClick={() => onPageClick(line)} />
              </div>
            )}
          </Draggable>
        );
      })}
    </div>
  );
};

export default Status;